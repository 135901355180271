import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { graphql } from 'gatsby'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'
import ShareBox from '@c/share-box'
import RedLine from '@c/red-line'
import CaseStudy from '@m/CaseStudy'
import makeSlug from '@u/make-slug'
import Story from '@v/references/story'

import './../resources/article/detail.css'
import { scrollIntoView } from '../../utils/scroll-into-view'
import useForceBlank from '../../hooks/use-force-blank'
import useCodeHighlighter from '@/hooks/use-code-highlighter'
import ProjectImage from '@/components/project-image'

const Page = ({ data }) => {
  const { item, pageSettings } = data
  const { referencesTitle, referencesSlug } = pageSettings
  const model = new CaseStudy(item)
  const parentPages = [
    {
      title: referencesTitle,
      url: makeSlug.generic(item.locale, referencesSlug)
    }
  ]
  const title = item.title
  const makeUrl = item => {
    return makeSlug.generic(
      item.locale,
      pageSettings.caseStudiesSlug,
      item.slug
    )
  }
  const url = `https://zeo.org${makeUrl(item)}`
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}`
  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    title
  )}&url=${encodeURIComponent(url)}`
  const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    url
  )}`
  const whatsappLink = `whatsapp://send?text=${encodeURIComponent(url)}`
  const mailLink = `mailto:?subject=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(url)}`
  let observer = null
  const [activeIndexElem, setActiveIndexElem] = useState(null)
  const content = useForceBlank(item.content)

  useEffect(() => {
    observer = new IntersectionObserver(entries => {
      let activeElem = null

      entries.forEach(entry => {
        if (entry.intersectionRatio <= 0) {
          return
        }

        console.log(entry.target, entry.intersectionRatio)
        activeElem = entry.target.id
      })

      if (activeElem) {
        setActiveIndexElem(activeElem)
      }
    })
  }, [])

  useEffect(() => {
    const elems = item.index
      .map(section => {
        return document.getElementById(section.sectionId)
      })
      .filter(elem => !!elem)

    elems.forEach(elem => observer.observe(elem))

    return () => elems.forEach(elem => observer.unobserve(elem))
  }, [observer, item.index])
  useCodeHighlighter()
  return (
    <Layout
      locale={item.locale}
      seo={{ ...model.getSeoComponentProps(pageSettings) }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <Breadcrumb
              className="resource-detail-breadcrumb"
              parentPages={parentPages}
              currentPage={title}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <article className="blog-post container px-0">
              {/* header */}
              <header className="blog-post-header d-flex flex-column align-items-start justify-content-center">
                <div className="col-12 mb-40">
                  {!!item?.image && <ProjectImage image={item.image} />}
                </div>
              </header>
              {/* body */}
              <div className="blog-post-body my-40">
                <div className="d-flex flex-row align-items-start">
                  <div className="col-2 d-none d-lg-block pl-32 u-sticky">
                    <RedLine />
                    {/* contents */}
                    {item.index.map((interPageLink, index) => {
                      return (
                        <div
                          className={cn(
                            'content-jumplink',
                            activeIndexElem === interPageLink.sectionId &&
                              'content-jumplink-active'
                          )}
                          key={index}
                        >
                          <div
                            className="pt-16"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              scrollIntoView(interPageLink.sectionId)
                            }
                          >
                            {interPageLink.title}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="col-12 col-lg-8">
                    <div className="col-12 pl-32">
                      {/* title */}
                      <PageHeader title={item.title} />
                      <div className="d-block d-lg-none">
                        {/* sharebox */}
                        <ShareBox
                          className="d-flex flex-row"
                          facebookLink={facebookLink}
                          twitterLink={twitterLink}
                          mailLink={mailLink}
                          whatsappLink={whatsappLink}
                          linkedinLink={linkedinLink}
                        />
                      </div>
                    </div>
                    <div
                      className="blog-post-content mt-80 pl-32"
                      dangerouslySetInnerHTML={{ __html: content }}
                    />
                  </div>
                  <div className="col-2 d-none d-lg-flex justify-content-end u-sticky">
                    {/* sharebox */}
                    <ShareBox
                      className="d-flex flex-column pr-32"
                      facebookLink={facebookLink}
                      twitterLink={twitterLink}
                      mailLink={mailLink}
                      whatsappLink={whatsappLink}
                      linkedinLink={linkedinLink}
                    />
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>

      {item?.caseStudies?.length > 0 && (
        <Story
          title={pageSettings?.otherCaseStudies}
          stories={item.caseStudies}
          pageSettings={pageSettings}
        />
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ArticleLikeDetailQuery($locale: String, $itemId: String) {
    item: datoCmsCaseStudy(id: { eq: $itemId }) {
      _allSlugLocales {
        locale
        value
      }
      locale
      title
      slug
      content
      descriptionSmall
      image {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      caseStudies {
        title
        slug
        description: descriptionSmall
        image: imageCard {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }

      index {
        title
        sectionId
      }
      metaTags {
        ...MetaTags
      }
    }
    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allCaseStudiesSlugLocales {
        locale
        value
      }
      locale
      caseStudiesTitle
      caseStudiesSlug
      otherCaseStudies
      referencesTitle
      referencesSlug
    }
  }
`
